import React from "react"
import { Link } from "gatsby"
import "../../i18n.js";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import BlogBanner from "../components/blog-banner/blogBanner"
import Ticker from "../components/ticker.js";

const Studio = () => {
  const { t } = useTranslation();

  const projects = [
    { title: t("komunTitle"), description: t("komunDescription"), link: "/tio-x-komun-case-study-re-building-an-app-and-a-website-for-a-scale-up/", img: "bg-komun" },
    { title: t("ddxTitle"), description: t("ddxDescription"), link: "/tio-x-ddx-hamle-case-study-web-based-platform-development/", img: "bg-ddx" },
    { title: t("212Title"), description: t("212Description"), link: "/tio-x-212-magazine-building-a-website-for-a-magazine-with-headless-cms-and-online-shop/", img: "bg-212" },
    { title: t("yikagelTitle"), description: t("yikagelDescription"), link: "/tio-x-yika-gel-case-study-last-mile-delivery-system-for-a-lean-start-up/", img: "bg-yikagel" },
  ]

  const moreProjects = [
    { title: t("cciTitle"), description: t("cciDescription"), link: null, tags: [t("uxResearchTag"), t("processVizTag"), t("storytellingTag")] }, //no link
    { title: t("tussideTitle"), description: t("tussideDescription"), link: null, tags: [t("uxResearchTag"), t("processVizTag"), t("storytellingTag")] },
    { title: t("codewayTitle"), description: t("codewayDescription"), link: "https://codeway.co", tags: [t("uxResearchTag"), t("uxTag"), t("uiTag"), t("webflowTag")] },
    { title: t("240dereceTitle"), description: t("240dereceDescription"), link: "/tio-x-240-derece-case-study-building-an-order-management-system-for-an-online-bakery/", tags: [t("uxResearchTag"), t("uxTag"), t("uiTag"), t("wireframingTag"), t("ecommerceTag"), t("dredTag"), t("tiobaseTag")] },
    { title: t("zerobooksTitle"), description: t("zerobooksDescription"), link: "https://zerobooksonline.com", tags: [t("uxResearchTag"), t("processVizTag"), t("storytellingTag")] },
    { title: t("shambhalaTitle"), description: t("shambhalaDescription"), link: null, tags: [t("uxResearchTag"), t("rebrandingTag"), t("uxTag"), t("uiTag"), t("wireframingTag"), t("serviceDesignTag"), t("dredTag"), t("ecommerceTag")] }, //no link
    { title: t("drylineTitle"), description: t("drylineDescription"), link: "https://drylinepartners.com", tags: [t("uxResearchTag"), t("uxTag"), t("uiTag"), t("webflowTag")] },
    { title: t("pollionTitle"), description: t("pollionDescription"), link: "https://pollion.net", tags: [t("brandingTag"), t("uxTag"), t("uiTag"), t("wireframingTag"), t("protoTypingTag"), t("webDevTag"), t("blogDevTag")] },
    { title: t("grandmaTitle"), description: t("grandmaDescription"), link: "https://grandma.com.tr", tags: [t("uxResearchTag"), t("wireframingTag"), t("uxTag"), t("uiTag"), t("serviceDesignTag"), t("dpmTag"), t("ecommerceTag")] },
    { title: t("savapaTitle"), description: t("savapaDesription"), link: "https://savapa.com", tags: [t("uxResearchTag"), t("processVizTag"), t("storytellingTag")] },
    { title: t("purviTitle"), description: t("purviDescription"), link: null, tags: [t("uxResearchTag"), t("brandingTag"), t("packageDesignTag")] }, //no link
  ]

  const ProjectBox = ({ title, link, description, img }) => (
    <div className="project-box">
      <p className="text-lightBlue text-lg md:text-sm lg:text-xl mb-3 z-20">{title}</p>
      <div className="relative text-4xl md:text-5xl lg:text-6xl group w-full">
        <h2 className="relative z-20">
          <a href={link} target="_blank" rel="noreferrer" className="text-cream font-bold leading-tight border-b-2 border-transparent">
            <span> {description}{" "}
              <svg className="inline transition-all ease-in-out duration-100 md:group-hover:hidden w-4 h-4 md:w-12 md:h-11 fill-current text-white" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.8636 45.8182L18.5909 40.5909L32.4318 26.75H0V19.0682H32.4318L18.5909 5.25L23.8636 0L46.7727 22.9091L23.8636 45.8182Z" />
              </svg>
              <svg className="hidden transition-all ease-in-out duration-100 md:group-hover:inline w-3 h-3 md:w-11 md:h-9 stroke-1 fill-current text-white" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.95455 34.8636L0 31.3182L30.7273 4.90909H10.3636L11.2273 0H40L35.2273 28.8182H30.2727L33.6818 8.45454L2.95455 34.8636Z" />
              </svg>
            </span>
            {/* <span className="hidden group-hover:inline transition-all ease-in-out duration-100">&nbsp;</span> */}
          </a>
        </h2>
        <div className={`project-image ${img} bg-no-repeat bg-right hidden bg-contain w-full h-96 absolute -top-20 -right-20 z-0 opacity-50 animate-float`} />
      </div>
    </div>
  )

  const MoreProjectBox = ({ title, link, description, tags }) => (
    <div className="more-project-box">
      <p className="text-lightBlue text-lg md:text-sm lg:text-xl mb-3 z-20">{title}</p>
      <div className="text-cream font-bold text-4xl md:text-5xl lg:text-6xl  w-full">
        {link ?
          (<h2>
            <a href={link} target="_blank" rel="noreferrer" className="group cursor-pointer z-20 leading-tight">
              <span>{description}{" "}
                <svg className="inline lg:group-hover:hidden w-4 h-4 md:w-12 md:h-11 fill-current text-white" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.8636 45.8182L18.5909 40.5909L32.4318 26.75H0V19.0682H32.4318L18.5909 5.25L23.8636 0L46.7727 22.9091L23.8636 45.8182Z" />
                </svg>
                <svg className="hidden lg:group-hover:inline w-3 h-3 md:w-11 md:h-9 stroke-1 fill-current text-white" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.95455 34.8636L0 31.3182L30.7273 4.90909H10.3636L11.2273 0H40L35.2273 28.8182H30.2727L33.6818 8.45454L2.95455 34.8636Z" />
                </svg>
              </span>
            </a>
          </h2>
          ) :
          (<span className="leading-tight z-20">
            {description}
          </span>)}
      </div>
      <div className="mt-3 lg:mt-4 inline-flex flex-wrap gap-3">
        {tags.map((tag, index) => (
          <span key={index} className="block w-auto text-white bg-shadeBlue px-3 py-2 text-xs">#{tag}</span>
        ))}
      </div>
    </div>
  )

  return (
    <Layout className="bg-blue">
      <Seo title={t("metaStudioTitle")} description={t("metaStudioDescription")} canonical="https://tio.ist/studio/" />
      <div className="bg-blue mt-12">
        <p className="text-lightBlue text-center lg:text-xl pb-12">{t("studioSubTitle")}</p>
        <Ticker color="white" text={t("studio")} type="sub" />
        <div className="flex justify-center my-8">
          <svg className="animate-bounce text-center fill-current text-lightBlue" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.35938 -4.76837e-07V12.8438L13.2969 7.89062L14.5156 9.09375L7.5 16.0938L0.5 9.09375L1.6875 7.89062L6.64063 12.8438V-4.76837e-07H8.35938Z" />
          </svg>
        </div>
        <div className="text-center text-cream mx-10 mb-40 md:mb-44 lg:mb-72">
          <p className="text-2xl lg:text-3xl max-w-xl mx-auto">
            {t("studioDescription1")}{" "}
            <span className="font-bold">{t("studioDescription2")}</span>{" "}
            {t("studioDescription3")}{" "}
            <span className="font-bold italic">{t("studioDescription4")}</span>{" "}
            {t("studioDescription5")}
          </p>
        </div>

        <div className="mx-5 md:mx-32 lg:mx-44">
          <p className="text-white text-center italic text-4xl md:text-5xl lg:text-6xl">{t("suchAs")}{" "}
            <svg className="fill-current text-white inline w-5 h-6 md:w-10 md:h-12" viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.2727 0L21.0909 37.3636L37.8182 22.9545L40.7727 26.4545L17 46.8182L0 26.4545L4.04545 22.9545L16.0909 37.3636L22.2727 0H27.2727Z" />
            </svg>
          </p>
          <div className="mt-16 md:mt-20 lg:mt-32 z-10 relative pb-28 lg:pb-44 mb-12 md:mb-14 lg:mb-16 space-y-12 md:space-y-14 lg:space-y-16">
            {projects.map((project, index) => (
              <ProjectBox key={index} title={project.title} description={project.description} link={project.link} img={project.img} />
            ))}
          </div>
        </div>

        <div className="bg-shadeBlue px-5 md:pl-32 lg:pl-44 pt-16 md:pt-24 lg:pt-44 pb-40 md:pb-44 lg:pb-60 md:pr-20 lg:pr-32 block">
          <p className="text-cream text-lg lg:text-3xl mb-12 md:max-w-lg">
            {t("studioBannerDescription")}
          </p>
          <div className="md:flex md:justify-end">
            <Link to="/contact" className="md:max-w-3xl text-right text-white text-6xl md:text-8xl lg:text-9xl">
              <span className="italic font-light">{t("studioBannerTitle1")}</span>{" "}
              <span className="font-bold">
                {t("studioBannerTitle2")}
              </span>{" "}
              <span className="italic font-light">{t("studioBannerTitle3")}</span>
            </Link>
          </div>
        </div>

        <div className="mx-5 md:mx-32 lg:mx-44 pt-24 lg:pt-36">
          <h2 className="text-white text-center italic text-4xl md:text-5xl lg:text-6xl">{t("andMore")}</h2>
          <div className="mt-16 md:mt-20 lg:mt-32 mb-12 md:mb-14 lg:mb-16 space-y-12 md:space-y-14 lg:space-y-16">
            {moreProjects.map((project, index) => (
              <MoreProjectBox key={index} title={project.title} description={project.description} link={project.link} tags={project.tags} />
            ))}
          </div>
        </div>

        <div className="pb-32 md:pb-40">
          <p className="text-lightBlue lg:text-xl text-center mb-20 md:mb-24 lg:mb-20">{t("trustedBy")}</p>
          <div className="overflow-x-scroll scrollbar-hidden">
            <div className="w-[390vw] md:w-[170vw]" >
              <StaticImage
                src="../images/company-logos.png"
                // width={3440}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="logos"
                // objectFit="contain"
                // objectPosition="static"
                // className="static"
                // className="pl-5"
                imgStyle={{ width: "100%" }}
              />

            </div>
          </div>
          {/* <img src={require("../images/company-logos.png")} alt="logos" width={200} height={200} /> */}

        </div>


      </div>
      <BlogBanner />
    </Layout>
  )
}

export default Studio